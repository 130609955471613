import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const PerfilDoClienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto; /* Page scrolls if necessary */
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; /* Reduced to avoid scroll */
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ClientImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
`;

const ClientImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 2px solid var(--color-darkorange);
  font-size: 0.9rem;
  cursor: pointer;
  text-align: center;
  min-width: 180px;

  &:hover {
    background-color: var(--color-darkorange);
    color: var(--color-white);
  }
`;

const SummarySection = styled.div`
  flex: 1;
  background-color: var(--color-black);
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  margin-right: 20px; /* Added to ensure space on large screens */
`;

const SummaryDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SummaryLabel = styled.span`
  font-size: 1.1rem;
`;

const SummaryValue = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-darkorange);
  margin-right: 20px; /* Added right margin for spacing */
`;

const DietTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  text-align: center;
  margin-bottom: 20px;
`;

const DietSection = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  background-color: var(--color-black);
  max-height: fit-content; /* Adjust to content size */
`;

const DietItemsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  overflow: visible; /* Prevent internal scrolling */
`;

const DietItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  max-width: 157px;

  &:hover {
    opacity: 0.8;
  }
`;

const DietImage = styled.img`
  width: 157px;
  height: 157px;
  border: 1px solid orange; /* Added border */
  border-radius: var(--br-5xs);
  object-fit: cover;
  margin-bottom: 10px;
`;

const AddDietItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const AddDietButton = styled.button`
  width: 157px;
  height: 157px;
  border-radius: 50%;
  border: none;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const PerfilDoCliente = () => {
  const { id } = useParams(); // Get the id from the URL
  const navigate = useNavigate();
  const [clientData, setClientData] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    if (id) {
      const db = getDatabase();
      const clientRef = dbRef(db, `customers/${id}`);

      onValue(clientRef, (snapshot) => {
        const data = snapshot.val();
        setClientData(data);

        // Fetch profile picture from Firebase Storage
        if (data.profile_pic) {
          const storage = getStorage();
          const picRef = storageRef(storage, data.profile_pic);
          getDownloadURL(picRef)
            .then((url) => setProfilePicUrl(url))
            .catch((error) => console.error("Error fetching profile pic:", error));
        }

        if (data.recipes) {
          // Fetch each recipe based on the recipe IDs
          const recipePromises = Object.keys(data.recipes).map(recipeId =>
            new Promise((resolve) => {
              const recipeRef = dbRef(db, `recipes/${recipeId}`);
              onValue(recipeRef, (recipeSnapshot) => {
                resolve({ id: recipeId, ...recipeSnapshot.val() });
              });
            })
          );

          // Resolve all promises and set recipes state
          Promise.all(recipePromises).then(setRecipes);
        }
      });
    }
  }, [id]);

  const onFrameContainerClick = () => {
    navigate("/");
  };

  const handleRecipeClick = (recipeId) => {
    navigate(`/receita/${recipeId}`);
  };

  if (!clientData) {
    return <div>Loading...</div>; // Add a loading state
  }

  return (
    <PerfilDoClienteRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Clientes > ${clientData.name || 'Cliente'}`} />
      <MainContent>
        <ContentWrapper>
          <DietTitle>Dieta de {clientData.name}</DietTitle> {/* Updated Title */}
          <HeroSection>
            <ClientImageWrapper>
              <ClientImage
                src={profilePicUrl || "/default-avatar.png"}
                alt={clientData.name}
              />
            </ClientImageWrapper>
            <ActionButtons>
              <ActionButton onClick={onFrameContainerClick}>Editar dados do cliente</ActionButton>
              <ActionButton>Editar dieta</ActionButton>
              <ActionButton>Imprimir receitas do cliente</ActionButton>
            </ActionButtons>
            <SummarySection>
              <SummaryDetail>
                <SummaryLabel>Valor gasto com o cliente:</SummaryLabel>
                <SummaryValue>R$: {clientData.totalSpent || 'N/A'}</SummaryValue>
              </SummaryDetail>
              <SummaryDetail>
                <SummaryLabel>Valor dos pratos do cliente:</SummaryLabel>
                <SummaryValue>R$: {clientData.dishValue || 'N/A'}</SummaryValue>
              </SummaryDetail>
            </SummarySection>
          </HeroSection>
          <DietTitle>Resumo do cliente</DietTitle>
          <DietSection>
            <DietItemsWrapper>
              {recipes.map((recipe) => (
                <DietItem key={recipe.id} onClick={() => handleRecipeClick(recipe.id)}>
                  <DietImage src={recipe.image || "/default-recipe-image.webp"} alt={recipe.description} />
                  <span>{recipe.description}</span>
                </DietItem>
              ))}
              <AddDietItem>
                <AddDietButton>+</AddDietButton>
                <span>Adicionar um prato</span>
              </AddDietItem>
            </DietItemsWrapper>
          </DietSection>
        </ContentWrapper>
      </MainContent>
    </PerfilDoClienteRoot>
  );
};

export default PerfilDoCliente;
