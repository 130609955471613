import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const SelecioneUmClienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SearchBar = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  overflow: hidden;
  height: 36px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
  .MuiInputBase-root {
    padding: 0;
    height: 36px;
    border: none;
    outline: none;
    font-size: 1rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiAutocomplete-inputRoot {
    padding: 8px 10px;
  }

  .MuiAutocomplete-option {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
  }
`;

const SearchButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: var(--color-darkorange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
`;

const ClientCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ClientImage = styled.img`
  width: 100%;
  max-width: 220px;
  max-height: 156px;
  border-radius: var(--br-5xs);
  object-fit: cover;
  border: 1px solid orange;
`;

const ClientName = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);
`;

const SelecioneUmCliente = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const storage = getStorage();
    const customersRef = dbRef(db, 'customers');

    onValue(customersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const clientPromises = Object.keys(data).map(async key => {
          const client = data[key];
          let imageUrl = "/default-avatar.png"; // Default image

          // Fetch profile picture from Firebase Storage if profile_pic path exists
          if (client.profile_pic) {
            try {
              const imageRef = storageRef(storage, client.profile_pic);
              imageUrl = await getDownloadURL(imageRef);
            } catch (error) {
              console.error("Error fetching profile pic:", error);
            }
          }

          return {
            id: key,
            name: client.name,
            image: imageUrl,
          };
        });

        Promise.all(clientPromises).then(formattedClients => {
          setClients(formattedClients);
          setFilteredClients(formattedClients);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (query.length > 2) {
      const filtered = clients.filter(client =>
        client.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredClients(filtered);
    } else {
      setFilteredClients(clients);
    }
  }, [query, clients]);

  const onClientClick = useCallback((clientPath) => {
    navigate(clientPath);
  }, [navigate]);

  return (
    <SelecioneUmClienteRoot>
      <FunctionsHeader
        onLogoClick={() => navigate("/home")}
        onClientClick={() => navigate("/clientes")}
        onIngredientClick={() => {}}
      />
      <Breadcrumb path="Página inicial > Clientes > Selecione um cliente" />
      <MainContent>
        <ContentWrapper>
          <SearchBar>
            <StyledAutocomplete
              freeSolo
              options={filteredClients}
              getOptionLabel={(option) => option.name}
              onInputChange={(event, newInputValue) => {
                setQuery(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder="Pesquise por cliente"
                />
              )}
            />
            <SearchButton>
              <img src="/group-4.svg" alt="Search Icon" />
            </SearchButton>
          </SearchBar>
          <ClientsGrid>
            {filteredClients.map(client => (
              <ClientCard key={client.id} onClick={() => onClientClick(`/cliente/${client.id}`)}>
                <ClientImage
                  src={client.image}
                  alt={client.name}
                />
                <ClientName>{client.name}</ClientName>
              </ClientCard>
            ))}
          </ClientsGrid>
        </ContentWrapper>
      </MainContent>
    </SelecioneUmClienteRoot>
  );
};

export default SelecioneUmCliente;
