import { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CurveLIcon = styled.img`
  width: 6px;
  position: relative;
  height: 8px;
`;
const Favicon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  object-fit: cover;
`;
const Google = styled.span`
  white-space: pre-wrap;
`;
const Span = styled.span`
  color: var(--color-gray-400);
`;
const TabName = styled.div`
  position: relative;
  letter-spacing: 0.2px;
`;
const CloseIcon = styled.img`
  width: 18px;
  position: relative;
  height: 18px;
`;
const Content = styled.div`
  border-radius: var(--br-5xs) var(--br-5xs) 0px 0px;
  background-color: var(--color-darkslategray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-9xs) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
`;
const CurveRIcon = styled.img`
  width: 6px;
  position: relative;
  height: 8px;
  object-fit: contain;
`;
const TabActive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
const PlusIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
`;
const EndTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  z-index: 0;
`;
const Tabs = styled.div`
  position: absolute;
  top: 8px;
  left: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const ChevronRightIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
`;
const RemoveIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;
const FrameChild = styled.div`
  position: absolute;
  top: 2.4px;
  left: 0px;
  border: 1.2px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 9.6px;
  height: 9.6px;
`;
const FrameItem = styled.img`
  position: absolute;
  top: 0px;
  left: 2.4px;
  width: 9.6px;
  height: 9px;
`;
const RectangleParent = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
`;
const PlusIcon1 = styled.div`
  width: 20px;
  position: relative;
  height: 20px;
`;
const Right = styled.div`
  position: absolute;
  top: 11px;
  right: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const ToolbarBrowserControls = styled.div`
  width: 1440px;
  position: relative;
  background-color: var(--color-gray-300);
  height: 42px;
  display: none;
`;
const ToolbarUrlControls = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 38px;
  overflow: hidden;
`;
const RightLockedIcons = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const IconSecure = styled.img`
  width: 12px;
  position: relative;
  height: 12px;
  object-fit: cover;
`;
const Domaincom = styled.div`
  position: relative;
  letter-spacing: 0.25px;
`;
const Subdomain = styled.div`
  position: relative;
  letter-spacing: 0.25px;
  color: var(--color-gray-500);
`;
const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const Url = styled.div`
  position: absolute;
  width: calc(100% - 177px);
  top: 5px;
  right: 43px;
  left: 134px;
  border-radius: var(--br-base);
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-7xs) var(--padding-3xs);
  box-sizing: border-box;
`;
const BackIcon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
`;
const LeftLockedIcons = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
`;
const ToolbarUrlControls1 = styled.div`
  width: 1440px;
  position: relative;
  background-color: var(--color-darkslategray-100);
  border-bottom: 1px solid var(--color-dimgray);
  box-sizing: border-box;
  height: 38px;
  display: none;
  font-size: var(--font-size-sm);
`;
const WindowsDesktopChromeBrows = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ComoVoceEst = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
`;
const PginaInicial1 = styled.div`
  width: 136px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 23px;
`;
const PginaInicialParent = styled.div`
  position: absolute;
  top: 201px;
  left: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--h6-size);
  color: var(--color-white);
  font-family: var(--small);
`;
const Tela1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--color-gray-200);
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
  font-family: var(--font-roboto);
`;
const ChamadaPrincipal1 = styled.div`
  position: absolute;
  top: 293px;
  left: 72px;
  width: 563px;
  height: 48px;
  color: var(--color-whitesmoke);
`;
const SoulbenBordadoIcon = styled.img`
  position: absolute;
  top: calc(50% - 37.5px);
  left: 72px;
  width: 148px;
  height: 75px;
  object-fit: cover;
  cursor: pointer;
`;
const Letcia = styled.div`
  position: absolute;
  top: 50px;
  left: 4px;
`;
const GroupChild = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-5xs);
  width: 48px;
  height: 48px;
  object-fit: cover;
`;
const LetciaParent = styled.div`
  position: absolute;
  top: 53px;
  left: 1611px;
  width: 48px;
  height: 66px;
`;
const Sugesto = styled.div`
  position: relative;
  cursor: pointer;
`;
const Rogrio = styled.div`
  position: relative;
`;
const Sidebar1 = styled.div`
  position: absolute;
  top: 62px;
  left: 0px;
  width: 740px;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
`;
const PesquisePorIngredientes = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;
`;
const Component5Child = styled.div`
  position: absolute;
  top: 0px;
  left: 400px;
  border-radius: var(--br-5xs) var(--br-5xs) 0px var(--br-5xs);
  background-color: var(--color-darkorange);
  width: 36px;
  height: 36px;
`;
const Component5Item = styled.img`
  position: absolute;
  top: 6px;
  left: 406px;
  width: 24px;
  height: 24px;
`;
const Component2 = styled.div`
  position: absolute;
  top: 0px;
  left: 152px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  width: 436px;
  height: 36px;
  font-size: var(--small-size);
  color: var(--color-black);
`;
const SidebarParent = styled.div`
  position: absolute;
  top: calc(50% - 40.5px);
  left: calc(50% - 370px);
  width: 740px;
  height: 81px;
  font-size: var(--p-size);
`;
const Topbar1 = styled.div`
  position: absolute;
  top: calc(50% - 540px);
  left: calc(50% - 960px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px var(--br-5xs) var(--br-5xs);
  background-color: var(--color-black);
  border-bottom: 2px solid var(--color-orange);
  box-sizing: border-box;
  width: 1920px;
  height: 173px;
  font-size: var(--small-size);
`;
const MenuBarChild = styled.div`
  position: absolute;
  top: 0px;
  left: 400px;
  border-radius: var(--br-5xs) var(--br-5xs) 0px var(--br-5xs);
  background-color: var(--color-darkorange);
  width: 36px;
  height: 36px;
  display: none;
`;
const MenuBarItem = styled.img`
  position: absolute;
  top: 6px;
  left: 406px;
  width: 24px;
  height: 24px;
  display: none;
`;
const MenuBar = styled.div`
  position: absolute;
  top: 373px;
  left: 72px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-darkorange);
  box-sizing: border-box;
  width: 402px;
  height: 36px;
  font-size: var(--small-size);
  color: var(--color-black);
`;
const BusqueOuPesquisePorUmIngrChild = styled.div`
  position: absolute;
  top: 373px;
  left: 465px;
  border-radius: 0px var(--br-5xs) var(--br-5xs) 0px;
  background-color: var(--color-darkorange);
  width: 36px;
  height: 36px;
`;
const ClientesChild = styled.img`
  width: 220px;
  position: relative;
  border-radius: var(--br-5xs);
  height: 156px;
  object-fit: cover;
`;
const Clientes2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const ClientesParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-72xl);
`;
const FrameParent = styled.div`
  position: absolute;
  top: 467px;
  left: 72px;
  width: 1777px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  font-size: var(--h4-size);
`;
const GroupItem = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkorange);
  width: 36px;
  height: 36px;
`;
const IcbaselineFilterAltIcon = styled.img`
  position: absolute;
  top: 6px;
  left: 6px;
  width: 24px;
  height: 24px;
  overflow: hidden;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 373px;
  left: 567px;
  width: 36px;
  height: 36px;
`;
const BusqueOuPesquisePorUmIngrItem = styled.img`
  position: absolute;
  top: 379px;
  left: 471px;
  width: 24px;
  height: 24px;
`;
const BusqueOuPesquisePorUmIngrInner = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 1920px;
  height: 1080px;
`;
const GroupInner = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-5xs);
  background-color: rgba(59, 59, 59, 0.98);
  border: 1px solid var(--color-darkorange);
  box-sizing: border-box;
  width: 1179px;
  height: 709.2px;
`;
const SelecioneOFiltro = styled.div`
  position: absolute;
  top: 56px;
  left: calc(50% - 157.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
`;
const InstanceChild = styled.div`
  width: 32px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-gainsboro);
  height: 32px;
`;
const FrameDiv = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const RectangleParent1 = styled.div`
  width: 250px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const InstanceParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-13xl);
`;
const InstanceGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const FrameGroup = styled.div`
  position: absolute;
  top: 176px;
  left: calc(50% - 296.5px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 105px;
  font-size: var(--h4-size);
`;
const SalvarWrapper = styled.div`
  width: 194px;
  border-radius: var(--br-5xs);
  background-color: var(--color-black);
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--color-darkorange);
`;
const LimparFiltrosWrapper = styled.div`
  width: 194px;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-base);
`;
const CancelarWrapper = styled.div`
  width: 194px;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-base);
  cursor: pointer;
`;
const FrameContainer = styled.div`
  position: absolute;
  top: 536px;
  left: calc(50% - 364.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-54xl);
  text-align: left;
  font-size: var(--h6-size);
`;
const RectangleContainer = styled.div`
  position: absolute;
  top: 185px;
  left: 371px;
  width: 1179px;
  height: 709.2px;
  text-align: center;
`;
const IcbaselineCloseIcon = styled.img`
  position: absolute;
  top: 185px;
  left: 1494px;
  width: 56px;
  height: 52.9px;
  overflow: hidden;
  cursor: pointer;
`;
const BusqueOuPesquisePorUmIngrRoot = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 1080px;
  text-align: left;
  font-size: var(--h2-size);
  color: var(--color-white);
  font-family: var(--small);
`;

const BusqueOuPesquisePorUmIngredienteFiltro = () => {
  const navigate = useNavigate();

  const onSoulBenBordadoImageClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onSUGESTO1TextClick = useCallback(() => {
    navigate("/selecione-um-cliente");
  }, [navigate]);

  const onSUGESTO2TextClick = useCallback(() => {
    navigate("/busque-ou-pesquise-por-um-ingrediente");
  }, [navigate]);

  return (
    <BusqueOuPesquisePorUmIngrRoot>
      <Tela1>
        <WindowsDesktopChromeBrows>
          <ToolbarBrowserControls>
            <Tabs>
              <EndTab>
                <TabActive>
                  <CurveLIcon alt="" src="/curve-l.svg" />
                  <Content>
                    <Favicon alt="" src="/favicon@2x.png" />
                    <TabName>
                      <Google>{`Google               `}</Google>
                      <Span>.</Span>
                    </TabName>
                    <CloseIcon alt="" src="/close.svg" />
                  </Content>
                  <CurveRIcon alt="" src="/curve-r.svg" />
                </TabActive>
                <PlusIcon alt="" src="/plus.svg" />
              </EndTab>
            </Tabs>
            <Right>
              <ChevronRightIcon alt="" src="/chevron-right@2x.png" />
              <RemoveIcon alt="" src="/remove.svg" />
              <PlusIcon1>
                <RectangleParent>
                  <FrameChild />
                  <FrameItem alt="" src="/rectangle-9.svg" />
                </RectangleParent>
              </PlusIcon1>
              <RemoveIcon alt="" src="/close1.svg" />
            </Right>
          </ToolbarBrowserControls>
          <ToolbarUrlControls1>
            <ToolbarUrlControls />
            <RightLockedIcons>
              <Favicon alt="" src="/icon--more@2x.png" />
            </RightLockedIcons>
            <Url>
              <Left>
                <IconSecure alt="" src="/icon--secure@2x.png" />
                <Text>
                  <Domaincom>domain.com</Domaincom>
                  <Subdomain>/subdomain/</Subdomain>
                </Text>
              </Left>
              <Favicon alt="" src="/icon--favorite@2x.png" />
            </Url>
            <LeftLockedIcons>
              <BackIcon alt="" src="/back.svg" />
              <BackIcon alt="" src="/forward.svg" />
              <BackIcon alt="" src="/refresh.svg" />
              <Favicon alt="" src="/home@2x.png" />
            </LeftLockedIcons>
          </ToolbarUrlControls1>
        </WindowsDesktopChromeBrows>
        <PginaInicialParent>
          <PginaInicial1>
            <ComoVoceEst>{`Página inicial >`}</ComoVoceEst>
          </PginaInicial1>
          <PginaInicial1>
            <ComoVoceEst>{`Página inicial >`}</ComoVoceEst>
          </PginaInicial1>
        </PginaInicialParent>
      </Tela1>
      <ChamadaPrincipal1>
        <ComoVoceEst>Selecione ou pesquise por um ingrediente</ComoVoceEst>
      </ChamadaPrincipal1>
      <Topbar1>
        <SoulbenBordadoIcon
          alt=""
          src="/soulben-bordado@2x.png"
          onClick={onSoulBenBordadoImageClick}
        />
        <LetciaParent>
          <Letcia>Letícia</Letcia>
          <GroupChild alt="" src="/rectangle-35@2x.png" />
        </LetciaParent>
        <SidebarParent>
          <Sidebar1>
            <Sugesto onClick={onSUGESTO1TextClick}>Clientes</Sugesto>
            <Sugesto onClick={onSUGESTO2TextClick}>Ingredientes</Sugesto>
            <Rogrio>Adicionar um cliente</Rogrio>
            <Rogrio>Adicionar um ingrediente</Rogrio>
            <Rogrio>Adicionar um prato</Rogrio>
          </Sidebar1>
          <Component2>
            <PesquisePorIngredientes>
              Pesquise por: Ingredientes, Receitas ou Clientes
            </PesquisePorIngredientes>
            <Component5Child />
            <Component5Item alt="" src="/group-4.svg" />
          </Component2>
        </SidebarParent>
      </Topbar1>
      <MenuBar>
        <PesquisePorIngredientes>
          Qual o nome do cliente que voce quer localizar ?
        </PesquisePorIngredientes>
        <MenuBarChild />
        <MenuBarItem alt="" src="/group-4.svg" />
      </MenuBar>
      <BusqueOuPesquisePorUmIngrChild />
      <FrameParent>
        <ClientesParent>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-158@2x.png" />
            <Rogrio>Batata</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-159@2x.png" />
            <Rogrio>Alface</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1510@2x.png" />
            <Rogrio>Cebola</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1511@2x.png" />
            <Rogrio>Brócolis</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1512@2x.png" />
            <Rogrio>Arroz</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1513@2x.png" />
            <Rogrio>Tomate</Rogrio>
          </Clientes2>
        </ClientesParent>
        <ClientesParent>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1514@2x.png" />
            <Rogrio>Feijão</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1515@2x.png" />
            <Rogrio>Acém</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1516@2x.png" />
            <Rogrio>Frango</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1517@2x.png" />
            <Rogrio>Repolho</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1518@2x.png" />
            <Rogrio>Pimentão</Rogrio>
          </Clientes2>
          <Clientes2>
            <ClientesChild alt="" src="/rectangle-1519@2x.png" />
            <Rogrio>Queijo</Rogrio>
          </Clientes2>
        </ClientesParent>
      </FrameParent>
      <RectangleGroup>
        <GroupItem />
        <IcbaselineFilterAltIcon alt="" src="/icbaselinefilteralt.svg" />
      </RectangleGroup>
      <BusqueOuPesquisePorUmIngrItem alt="" src="/group-4.svg" />
      <BusqueOuPesquisePorUmIngrInner />
      <RectangleContainer>
        <GroupInner />
        <SelecioneOFiltro>Selecione o filtro</SelecioneOFiltro>
        <FrameGroup>
          <InstanceParent>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Carnes bovinas</Rogrio>
            </FrameDiv>
            <RectangleParent1>
              <InstanceChild />
              <Rogrio>Carnes suínas</Rogrio>
            </RectangleParent1>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Frango</Rogrio>
            </FrameDiv>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Legumes</Rogrio>
            </FrameDiv>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Vegetais</Rogrio>
            </FrameDiv>
          </InstanceParent>
          <InstanceGroup>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Frutos do mar</Rogrio>
            </FrameDiv>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Cogumelos</Rogrio>
            </FrameDiv>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Mais usados</Rogrio>
            </FrameDiv>
            <FrameDiv>
              <InstanceChild />
              <Rogrio>Menos usados</Rogrio>
            </FrameDiv>
          </InstanceGroup>
        </FrameGroup>
        <FrameContainer>
          <SalvarWrapper onClick={onSUGESTO2TextClick}>
            <Rogrio>Salvar</Rogrio>
          </SalvarWrapper>
          <LimparFiltrosWrapper>
            <Rogrio>Limpar filtros</Rogrio>
          </LimparFiltrosWrapper>
          <CancelarWrapper onClick={onSUGESTO2TextClick}>
            <Rogrio>Cancelar</Rogrio>
          </CancelarWrapper>
        </FrameContainer>
      </RectangleContainer>
      <IcbaselineCloseIcon
        alt=""
        src="/icbaselineclose.svg"
        onClick={onSUGESTO2TextClick}
      />
    </BusqueOuPesquisePorUmIngrRoot>
  );
};

export default BusqueOuPesquisePorUmIngredienteFiltro;
