import { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CurveLIcon = styled.img`
  width: 6px;
  position: relative;
  height: 8px;
`;
const Favicon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  object-fit: cover;
`;
const Google = styled.span`
  white-space: pre-wrap;
`;
const Span = styled.span`
  color: var(--color-gray-400);
`;
const TabName = styled.div`
  position: relative;
  letter-spacing: 0.2px;
`;
const CloseIcon = styled.img`
  width: 18px;
  position: relative;
  height: 18px;
`;
const Content = styled.div`
  border-radius: var(--br-5xs) var(--br-5xs) 0px 0px;
  background-color: var(--color-darkslategray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-9xs) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
`;
const CurveRIcon = styled.img`
  width: 6px;
  position: relative;
  height: 8px;
  object-fit: contain;
`;
const TabActive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
const PlusIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
`;
const EndTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  z-index: 0;
`;
const Tabs = styled.div`
  position: absolute;
  top: 8px;
  left: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const ChevronRightIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
`;
const RemoveIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;
const FrameChild = styled.div`
  position: absolute;
  top: 2.4px;
  left: 0px;
  border: 1.2px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 9.6px;
  height: 9.6px;
`;
const FrameItem = styled.img`
  position: absolute;
  top: 0px;
  left: 2.4px;
  width: 9.6px;
  height: 9px;
`;
const RectangleParent = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
`;
const PlusIcon1 = styled.div`
  width: 20px;
  position: relative;
  height: 20px;
`;
const Right = styled.div`
  position: absolute;
  top: 11px;
  right: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const ToolbarBrowserControls = styled.div`
  width: 1440px;
  position: relative;
  background-color: var(--color-gray-300);
  height: 42px;
  display: none;
`;
const ToolbarUrlControls = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 38px;
  overflow: hidden;
`;
const RightLockedIcons = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const IconSecure = styled.img`
  width: 12px;
  position: relative;
  height: 12px;
  object-fit: cover;
`;
const Domaincom = styled.div`
  position: relative;
  letter-spacing: 0.25px;
`;
const Subdomain = styled.div`
  position: relative;
  letter-spacing: 0.25px;
  color: var(--color-gray-500);
`;
const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const Url = styled.div`
  position: absolute;
  width: calc(100% - 177px);
  top: 5px;
  right: 43px;
  left: 134px;
  border-radius: var(--br-base);
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-7xs) var(--padding-3xs);
  box-sizing: border-box;
`;
const BackIcon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
`;
const LeftLockedIcons = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
`;
const ToolbarUrlControls1 = styled.div`
  width: 1440px;
  position: relative;
  background-color: var(--color-darkslategray-100);
  border-bottom: 1px solid var(--color-dimgray);
  box-sizing: border-box;
  height: 38px;
  display: none;
  font-size: var(--font-size-sm);
`;
const WindowsDesktopChromeBrows = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const PginaInicial1 = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  display: none;
`;
const PginaInicial2 = styled.div`
  position: absolute;
  top: 201px;
  left: 220px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 136px;
  height: 23px;
  font-size: var(--h6-size);
  color: var(--color-white);
  font-family: var(--small);
`;
const Tela1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--color-gray-200);
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  font-family: var(--font-roboto);
`;
const SoulbenBordadoIcon = styled.img`
  position: absolute;
  top: 49px;
  left: 846px;
  width: 148px;
  height: 75px;
  object-fit: cover;
`;
const PesquisePorIngredientes = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;
`;
const MenuBarChild = styled.div`
  position: absolute;
  top: 0px;
  left: 400px;
  border-radius: var(--br-5xs) var(--br-5xs) 0px var(--br-5xs);
  background-color: var(--color-darkorange);
  width: 36px;
  height: 36px;
`;
const MenuBarItem = styled.img`
  position: absolute;
  top: 6px;
  left: 406px;
  width: 24px;
  height: 24px;
`;
const MenuBar = styled.div`
  width: 436px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-darkorange);
  box-sizing: border-box;
  height: 36px;
`;
const Concludo = styled.div`
  position: relative;
`;
const Sidebar1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: var(--p-size);
  color: var(--color-white);
`;
const MenuBarParent = styled.div`
  position: absolute;
  top: 43px;
  left: 663px;
  width: 594px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
`;
const Letcia = styled.div`
  position: absolute;
  top: 50px;
  left: 4px;
`;
const GroupChild = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-5xs);
  width: 48px;
  height: 48px;
  object-fit: cover;
`;
const LetciaParent = styled.div`
  position: absolute;
  top: 53px;
  left: 1611px;
  width: 48px;
  height: 66px;
  display: none;
  color: var(--color-white);
`;
const Topbar1 = styled.div`
  position: absolute;
  top: calc(50% - 86.5px);
  left: calc(50% - 960px);
  border-radius: 0px 0px var(--br-5xs) var(--br-5xs);
  background-color: var(--color-black);
  border-bottom: 2px solid var(--color-orange);
  box-sizing: border-box;
  width: 1920px;
  height: 173px;
`;
const TopbarWrapper = styled.div`
  position: absolute;
  top: calc(50% - 86.5px);
  left: calc(50% - 960px);
  width: 1920px;
  height: 173px;
`;
const GerenciarPerfisInner = styled.div`
  position: absolute;
  top: calc(50% - 540px);
  left: calc(50% - 960px);
  width: 1920px;
  height: 173px;
  font-size: var(--small-size);
  color: var(--color-black);
`;
const ComoVoceEst = styled.div`
  position: absolute;
  top: -10.42%;
  left: 0%;
`;
const ChamadaPrincipal1 = styled.div`
  width: 563px;
  position: relative;
  height: 48px;
`;
const MenusChild = styled.img`
  width: 364px;
  position: relative;
  border-radius: var(--br-5xl);
  height: 242px;
  object-fit: cover;
`;
const Leticia = styled.p`
  margin: 0;
`;
const ClientesTxt = styled.span`
  line-break: anywhere;
  width: 100%;
`;
const Clientes2 = styled.div`
  width: 364px;
  position: relative;
  display: flex;
  align-items: center;
`;
const Menus1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const IcbaselineEditIcon = styled.img`
  position: absolute;
  top: 89px;
  left: 150px;
  width: 64px;
  height: 64px;
  overflow: hidden;
`;
const MenusParent = styled.div`
  width: 364px;
  position: relative;
  height: 332px;
  cursor: pointer;
  z-index: 0;
`;
const MenusGroup = styled.div`
  width: 364px;
  position: relative;
  height: 332px;
  z-index: 1;
`;
const Menus2 = styled.div`
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  z-index: 2;
`;
const RectangleDiv = styled.div`
  width: 364px;
  position: relative;
  border-radius: var(--br-5xl);
  background: linear-gradient(#000, #000), #d9d9d9;
  border-bottom: 1px solid var(--color-orange);
  box-sizing: border-box;
  height: 242px;
`;
const ClientesTxt1 = styled.span`
  width: 100%;
`;
const IcbaselineAddCircleOutlineIcon = styled.img`
  position: absolute;
  top: 97px;
  left: 158px;
  width: 48px;
  height: 48px;
  overflow: hidden;
`;
const MenusContainer = styled.div`
  width: 364px;
  position: relative;
  height: 332px;
  z-index: 3;
`;
const FrameInner = styled.img`
  width: 64px;
  position: absolute;
  margin: 0 !important;
  top: 89px;
  left: 926px;
  height: 64px;
  z-index: 4;
`;
const GroupParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--h4-size);
`;
const ChamadaPrincipalParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-45xl);
`;
const ConcludoWrapper = styled.div`
  width: 194px;
  border: 1px solid var(--color-darkgray);
  box-sizing: border-box;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base);
  font-size: var(--p-size);
  color: var(--color-gainsboro);
`;
const FrameParent = styled.div`
  position: absolute;
  top: 318px;
  left: 196px;
  width: 1528px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-size: var(--h1-size);
  color: var(--color-whitesmoke);
`;
const GerenciarPerfisRoot = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 1080px;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
  font-family: var(--small);
`;

const GerenciarPerfis = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/editar-perfil");
  }, [navigate]);

  return (
    <GerenciarPerfisRoot>
      <Tela1>
        <WindowsDesktopChromeBrows>
          <ToolbarBrowserControls>
            <Tabs>
              <EndTab>
                <TabActive>
                  <CurveLIcon alt="" src="/curve-l.svg" />
                  <Content>
                    <Favicon alt="" src="/favicon@2x.png" />
                    <TabName>
                      <Google>{`Google               `}</Google>
                      <Span>.</Span>
                    </TabName>
                    <CloseIcon alt="" src="/close.svg" />
                  </Content>
                  <CurveRIcon alt="" src="/curve-r.svg" />
                </TabActive>
                <PlusIcon alt="" src="/plus.svg" />
              </EndTab>
            </Tabs>
            <Right>
              <ChevronRightIcon alt="" src="/chevron-right@2x.png" />
              <RemoveIcon alt="" src="/remove.svg" />
              <PlusIcon1>
                <RectangleParent>
                  <FrameChild />
                  <FrameItem alt="" src="/rectangle-9.svg" />
                </RectangleParent>
              </PlusIcon1>
              <RemoveIcon alt="" src="/close1.svg" />
            </Right>
          </ToolbarBrowserControls>
          <ToolbarUrlControls1>
            <ToolbarUrlControls />
            <RightLockedIcons>
              <Favicon alt="" src="/icon--more@2x.png" />
            </RightLockedIcons>
            <Url>
              <Left>
                <IconSecure alt="" src="/icon--secure@2x.png" />
                <Text>
                  <Domaincom>domain.com</Domaincom>
                  <Subdomain>/subdomain/</Subdomain>
                </Text>
              </Left>
              <Favicon alt="" src="/icon--favorite@2x.png" />
            </Url>
            <LeftLockedIcons>
              <BackIcon alt="" src="/back.svg" />
              <BackIcon alt="" src="/forward.svg" />
              <BackIcon alt="" src="/refresh.svg" />
              <Favicon alt="" src="/home@2x.png" />
            </LeftLockedIcons>
          </ToolbarUrlControls1>
        </WindowsDesktopChromeBrows>
        <PginaInicial2>
          <PginaInicial1>{`Página inicial >`}</PginaInicial1>
        </PginaInicial2>
      </Tela1>
      <GerenciarPerfisInner>
        <TopbarWrapper>
          <Topbar1>
            <SoulbenBordadoIcon alt="" src="/soulben-bordado@2x.png" />
            <MenuBarParent>
              <MenuBar>
                <PesquisePorIngredientes>
                  Pesquise por ingredientes, receitas ou pessoas
                </PesquisePorIngredientes>
                <MenuBarChild />
                <MenuBarItem alt="" src="/group-4.svg" />
              </MenuBar>
              <Sidebar1>
                <Concludo>SUGESTÃO 1</Concludo>
                <Concludo>SUGESTÃO 2</Concludo>
                <Concludo>SUGESTÃO 3</Concludo>
                <Concludo>SUGESTÃO 4</Concludo>
                <Concludo>SUGESTÃO 5</Concludo>
              </Sidebar1>
            </MenuBarParent>
            <LetciaParent>
              <Letcia>Letícia</Letcia>
              <GroupChild alt="" src="/rectangle-35@2x.png" />
            </LetciaParent>
          </Topbar1>
        </TopbarWrapper>
      </GerenciarPerfisInner>
      <FrameParent>
        <ChamadaPrincipalParent>
          <ChamadaPrincipal1>
            <ComoVoceEst>Gerenciar perfis:</ComoVoceEst>
          </ChamadaPrincipal1>
          <GroupParent>
            <MenusParent onClick={onGroupContainerClick}>
              <Menus1>
                <MenusChild alt="" src="/rectangle-151@2x.png" />
                <Clientes2>
                  <ClientesTxt>
                    <Leticia>Leticia</Leticia>
                  </ClientesTxt>
                </Clientes2>
              </Menus1>
              <IcbaselineEditIcon alt="" src="/icbaselineedit.svg" />
            </MenusParent>
            <MenusGroup>
              <Menus1>
                <MenusChild alt="" src="/rectangle-152@2x.png" />
                <Clientes2>
                  <ClientesTxt>
                    <Leticia>Paula</Leticia>
                  </ClientesTxt>
                </Clientes2>
              </Menus1>
              <IcbaselineEditIcon alt="" src="/icbaselineedit.svg" />
            </MenusGroup>
            <Menus2>
              <MenusChild alt="" src="/rectangle-153@2x.png" />
              <Clientes2>
                <ClientesTxt>
                  <Leticia>Rodney</Leticia>
                </ClientesTxt>
              </Clientes2>
            </Menus2>
            <MenusContainer>
              <Menus1>
                <RectangleDiv />
                <Clientes2>
                  <ClientesTxt1>
                    <Leticia>Adicionar um perfil</Leticia>
                  </ClientesTxt1>
                </Clientes2>
              </Menus1>
              <IcbaselineAddCircleOutlineIcon
                alt=""
                src="/icbaselineaddcircleoutline.svg"
              />
            </MenusContainer>
            <FrameInner alt="" src="/icbaselineedit.svg" />
          </GroupParent>
        </ChamadaPrincipalParent>
        <ConcludoWrapper>
          <Concludo>Concluído</Concludo>
        </ConcludoWrapper>
      </FrameParent>
    </GerenciarPerfisRoot>
  );
};

export default GerenciarPerfis;
