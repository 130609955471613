import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--small: Inter;
--font-roboto: Roboto;

/* font sizes */
--h4-size: 27.6px;
--small-size: 13.3px;
--p-size: 16px;
--h2-size: 39.8px;
--h6-size: 19.2px;
--font-size-sm: 14px;
--font-size-xs: 12px;
--h1-size: 47.8px;
--h5-size: 23px;

/* Colors */
--color-whitesmoke: #ebebeb;
--color-black: #000;
--color-orange: #fcb211;
--color-white: #fff;
--color-darkorange: #ff8e00;
--color-gray-100: #797a7c;
--color-gray-200: #242424;
--color-gray-300: #202124;
--color-gray-400: rgba(255, 255, 255, 0);
--color-gray-500: rgba(255, 255, 255, 0.4);
--color-darkslategray-100: #35363a;
--color-dimgray: #4d4d4d;
--color-darkgray: #9a9a9a;
--color-gainsboro: #d9d9d9;

/* Gaps */
--gap-61xl: 80px;
--gap-5xl: 24px;
--gap-xs: 12px;
--gap-mini: 15px;
--gap-3xs: 10px;
--gap-xl: 20px;
--gap-5xs: 8px;
--gap-29xl: 48px;
--gap-13xl: 32px;
--gap-45xl: 64px;
--gap-21xl: 40px;
--gap-72xl: 91px;
--gap-base: 16px;
--gap-54xl: 73px;

/* Paddings */
--padding-7xs: 6px;
--padding-3xs: 10px;
--padding-5xs: 8px;
--padding-9xs: 4px;
--padding-base: 16px;
--padding-35xl: 54px;
--padding-53xl: 72px;
--padding-6xs: 7px;
--padding-57xl: 76px;
--padding-mini: 15px;

/* Border radiuses */
--br-base: 16px;
--br-5xl: 24px;
--br-5xs: 8px;
--br-581xl: 600px;

}
`;
