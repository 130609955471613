import React, { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const PerfilIngredienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const IngredientImage = styled.img`
  width: 159px;
  height: 159px;
  border-radius: var(--br-581xl);
  object-fit: cover;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActionButton = styled.button`
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-darkorange);
  padding: var(--padding-6xs) var(--padding-mini);
  cursor: pointer;
  font-size: 1rem;
  color: var(--color-black);
  text-align: center;
  width: 200px;
`;

const RecipesWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecipesTitle = styled.h2`
  color: var(--color-whitesmoke);
  font-size: var(--h4-size);
  margin-bottom: 30px;
`;

const RecipesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
`;

const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const RecipeImage = styled.img`
  width: 100%;
  max-width: 220px;
  height: 156px;
  border-radius: var(--br-5xs);
  object-fit: cover;
  margin-bottom: 10px;
`;

const RecipeTitle = styled.div`
  font-size: 1rem;
  color: var(--color-white);
`;

const AddRecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 156px;
  border-radius: var(--br-5xs);
  background-color: var(--color-black);
  border: 1px solid var(--color-white);
  cursor: pointer;
`;

const AddRecipeIcon = styled.img`
  width: 90px;
  height: 90px;
`;

const AddRecipeText = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);
`;

const PerfilIngrediente = () => {
  const navigate = useNavigate();

  const onComponent3ContainerClick = useCallback(() => {
    navigate("/precificao-batata");
  }, [navigate]);

  const onComponent4ContainerClick = useCallback(() => {
    navigate("/resumo-batata");
  }, [navigate]);

  const onRecipeClick = useCallback((path) => {
    navigate(path);
  }, [navigate]);

  return (
    <PerfilIngredienteRoot>
      <FunctionsHeader
        onLogoClick={() => navigate("/home")}
        onClientClick={() => navigate("/clientes")}
        onIngredientClick={() => navigate("/ingredientes")}
      />
      <Breadcrumb path="Página inicial > Ingredientes 1 > Ingredientes 2 > Batata" />
      <MainContent>
        <ContentWrapper>
          <HeaderWrapper>
            <IngredientImage src="/group-8@2x.png" alt="Batata" />
            <ActionsWrapper>
              <ActionButton onClick={onComponent3ContainerClick}>
                Precificação
              </ActionButton>
              <ActionButton onClick={onComponent4ContainerClick}>
                Resumo sobre o ingrediente
              </ActionButton>
            </ActionsWrapper>
          </HeaderWrapper>
          <RecipesWrapper>
            <RecipesTitle>Receitas que contêm o ingrediente</RecipesTitle>
            <RecipesGrid>
              <RecipeCard onClick={() => onRecipeClick("/frango-a-passarinho")}>
                <RecipeImage src="/rectangle-351@2x.png" alt="Frango a passarinho" />
                <RecipeTitle>Frango a passarinho</RecipeTitle>
              </RecipeCard>
              <RecipeCard onClick={() => onRecipeClick("/panqueca-e-mel")}>
                <RecipeImage src="/rectangle-352@2x.png" alt="Panqueca e mel" />
                <RecipeTitle>Panqueca e mel</RecipeTitle>
              </RecipeCard>
              <RecipeCard onClick={() => onRecipeClick("/salada-de-ovos")}>
                <RecipeImage src="/rectangle-353@2x.png" alt="Salada de ovos" />
                <RecipeTitle>Salada de ovos</RecipeTitle>
              </RecipeCard>
              <RecipeCard onClick={() => onRecipeClick("/salmao")}>
                <RecipeImage src="/rectangle-354@2x.png" alt="Salmão" />
                <RecipeTitle>Salmão</RecipeTitle>
              </RecipeCard>
              <RecipeCard onClick={() => onRecipeClick("/lanche-natural")}>
                <RecipeImage src="/rectangle-355@2x.png" alt="Lanche natural" />
                <RecipeTitle>Lanche natural</RecipeTitle>
              </RecipeCard>
              <AddRecipeCard onClick={() => onRecipeClick("/adicionar-prato")}>
                <AddRecipeIcon src="/icoutlineplus.svg" alt="Adicionar um prato" />
                <AddRecipeText>Adicionar um prato</AddRecipeText>
              </AddRecipeCard>
            </RecipesGrid>
          </RecipesWrapper>
        </ContentWrapper>
      </MainContent>
    </PerfilIngredienteRoot>
  );
};

export default PerfilIngrediente;
