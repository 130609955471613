import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const BreadcrumbContainer = styled.div`
  margin: 20px;
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--small);
  display: flex;
  align-items: center;
`;

const Crumb = styled.span`
  cursor: pointer;
  color: var(--color-white);
  transition: color 0.3s;

  &:hover {
    color: var(--color-darkorange);
  }
`;

const Separator = styled.span`
  margin: 0 8px;
`;

const Breadcrumb = ({ path }) => {
  const navigate = useNavigate();
  
  const handleCrumbClick = (index, paths) => {
    const newPath = paths.slice(0, index + 1).join('/');
    navigate(newPath);
  };

  const paths = path.split(' > ');

  return (
    <BreadcrumbContainer>
      {paths.map((crumb, index) => (
        <React.Fragment key={index}>
          <Crumb onClick={() => handleCrumbClick(index, paths)}>
            {crumb}
          </Crumb>
          {index < paths.length - 1 && <Separator>{">"}</Separator>}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
