import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.header`
  width: 100%;
  background-color: #000; /* Full black background */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 0;
`;

const Logo = styled.img`
  height: 75px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const FooterLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f5bb09; /* Yellow line color */
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Logo src="/soulben-bordado@2x.png" alt="SoulBen Logo" />
      <FooterLine />
    </HeaderContainer>
  );
};

export default Header;
