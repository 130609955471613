import { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CurveLIcon = styled.img`
  width: 6px;
  position: relative;
  height: 8px;
`;
const Favicon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  object-fit: cover;
`;
const Google = styled.span`
  white-space: pre-wrap;
`;
const Span = styled.span`
  color: var(--color-gray-400);
`;
const TabName = styled.div`
  position: relative;
  letter-spacing: 0.2px;
`;
const CloseIcon = styled.img`
  width: 18px;
  position: relative;
  height: 18px;
`;
const Content = styled.div`
  border-radius: var(--br-5xs) var(--br-5xs) 0px 0px;
  background-color: var(--color-darkslategray-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-9xs) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
`;
const CurveRIcon = styled.img`
  width: 6px;
  position: relative;
  height: 8px;
  object-fit: contain;
`;
const TabActive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
const PlusIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
`;
const EndTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  z-index: 0;
`;
const Tabs = styled.div`
  position: absolute;
  top: 8px;
  left: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const ChevronRightIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
`;
const RemoveIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;
const FrameChild = styled.div`
  position: absolute;
  top: 2.4px;
  left: 0px;
  border: 1.2px solid var(--color-gray-100);
  box-sizing: border-box;
  width: 9.6px;
  height: 9.6px;
`;
const FrameItem = styled.img`
  position: absolute;
  top: 0px;
  left: 2.4px;
  width: 9.6px;
  height: 9px;
`;
const RectangleParent = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
`;
const PlusIcon1 = styled.div`
  width: 20px;
  position: relative;
  height: 20px;
`;
const Right = styled.div`
  position: absolute;
  top: 11px;
  right: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const ToolbarBrowserControls = styled.div`
  width: 1440px;
  position: relative;
  background-color: var(--color-gray-300);
  height: 42px;
  display: none;
`;
const ToolbarUrlControls = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 38px;
  overflow: hidden;
`;
const RightLockedIcons = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const IconSecure = styled.img`
  width: 12px;
  position: relative;
  height: 12px;
  object-fit: cover;
`;
const Domaincom = styled.div`
  position: relative;
  letter-spacing: 0.25px;
`;
const Subdomain = styled.div`
  position: relative;
  letter-spacing: 0.25px;
  color: var(--color-gray-500);
`;
const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const Url = styled.div`
  position: absolute;
  width: calc(100% - 177px);
  top: 5px;
  right: 43px;
  left: 134px;
  border-radius: var(--br-base);
  background-color: var(--color-gray-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-7xs) var(--padding-3xs);
  box-sizing: border-box;
`;
const BackIcon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
`;
const LeftLockedIcons = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
`;
const ToolbarUrlControls1 = styled.div`
  width: 1440px;
  position: relative;
  background-color: var(--color-darkslategray-100);
  border-bottom: 1px solid var(--color-dimgray);
  box-sizing: border-box;
  height: 38px;
  display: none;
  font-size: var(--font-size-sm);
`;
const WindowsDesktopChromeBrows = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ComoVoceEst = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
`;
const PginaInicial1 = styled.div`
  width: 136px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 23px;
`;
const PginaInicialParent = styled.div`
  position: absolute;
  top: 201px;
  left: 72px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--h6-size);
  color: var(--color-white);
  font-family: var(--small);
`;
const Tela1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--color-gray-200);
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
  font-family: var(--font-roboto);
`;
const ChamadaPrincipal1 = styled.div`
  position: absolute;
  top: 585px;
  left: 256px;
  width: 130px;
  height: 48px;
  font-size: var(--h2-size);
  color: var(--color-whitesmoke);
`;
const PrecificaoBatataChild = styled.img`
  position: absolute;
  top: 410px;
  left: 241px;
  width: 159px;
  height: 159px;
  object-fit: cover;
`;
const Data = styled.div`
  align-self: stretch;
  position: relative;
  text-shadow:
    1px 0 0 #000,
    0 1px 0 #000,
    -1px 0 0 #000,
    0 -1px 0 #000;
`;
const Data1 = styled.div`
  align-self: stretch;
  position: relative;
`;
const Data1Parent = styled.div`
  position: absolute;
  top: 77px;
  left: 70px;
  width: 384px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Preo1Parent = styled.div`
  position: absolute;
  top: 77px;
  left: 576px;
  width: 384px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const FrameInner = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--color-white);
  box-sizing: border-box;
  height: 1px;
`;
const LineDiv = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--color-orange);
  box-sizing: border-box;
  height: 1px;
`;
const LineParent = styled.div`
  position: absolute;
  top: 107px;
  left: calc(50% - 514px);
  width: 1028px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
`;
const FrameParent = styled.div`
  position: absolute;
  top: calc(50% - 148px);
  left: calc(50% - 171px);
  border-radius: var(--br-5xl);
  background-color: var(--color-black);
  border-bottom: 1px solid var(--color-orange);
  box-sizing: border-box;
  width: 1028px;
  height: 424px;
`;
const SoulbenBordadoIcon = styled.img`
  position: absolute;
  top: calc(50% - 37.5px);
  left: 72px;
  width: 148px;
  height: 75px;
  object-fit: cover;
  cursor: pointer;
`;
const Letcia = styled.div`
  position: absolute;
  top: 50px;
  left: 4px;
`;
const GroupChild = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-5xs);
  width: 48px;
  height: 48px;
  object-fit: cover;
`;
const LetciaParent = styled.div`
  position: absolute;
  top: 53px;
  left: 1611px;
  width: 48px;
  height: 66px;
`;
const Sugesto = styled.div`
  position: relative;
  cursor: pointer;
`;
const Sugesto1 = styled.div`
  position: relative;
`;
const Sidebar1 = styled.div`
  position: absolute;
  top: 62px;
  left: 0px;
  width: 740px;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
`;
const PesquisePorIngredientes = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;
`;
const Component5Child = styled.div`
  position: absolute;
  top: 0px;
  left: 400px;
  border-radius: var(--br-5xs) var(--br-5xs) 0px var(--br-5xs);
  background-color: var(--color-darkorange);
  width: 36px;
  height: 36px;
`;
const Component5Item = styled.img`
  position: absolute;
  top: 6px;
  left: 406px;
  width: 24px;
  height: 24px;
`;
const Component2 = styled.div`
  position: absolute;
  top: 0px;
  left: 152px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  width: 436px;
  height: 36px;
  font-size: var(--small-size);
  color: var(--color-black);
`;
const SidebarParent = styled.div`
  position: absolute;
  top: calc(50% - 40.5px);
  left: calc(50% - 370px);
  width: 740px;
  height: 81px;
  font-size: var(--p-size);
`;
const Topbar1 = styled.div`
  position: absolute;
  top: calc(50% - 537px);
  left: calc(50% - 960px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px var(--br-5xs) var(--br-5xs);
  background-color: var(--color-black);
  border-bottom: 2px solid var(--color-orange);
  box-sizing: border-box;
  width: 1920px;
  height: 173px;
  font-size: var(--small-size);
`;
const MenuBar = styled.div`
  position: absolute;
  top: 326px;
  left: 103px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-darkorange);
  box-sizing: border-box;
  width: 436px;
  height: 36px;
  font-size: var(--small-size);
  color: var(--color-black);
`;
const HistricoDePrecificao = styled.div`
  position: absolute;
  top: 296px;
  left: 1039px;
  font-size: var(--h2-size);
`;
const PginaInicialGroup = styled.div`
  position: absolute;
  top: 227px;
  left: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  cursor: pointer;
`;
const PginaInicial2 = styled.div`
  position: absolute;
  top: 227px;
  left: 368px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 148px;
  height: 23px;
  cursor: pointer;
`;
const PginaInicial3 = styled.div`
  position: absolute;
  top: 225px;
  left: 528px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 80px;
  height: 23px;
  cursor: pointer;
`;
const PginaInicial4 = styled.div`
  position: absolute;
  top: 227px;
  left: 620px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 136px;
  height: 23px;
  cursor: pointer;
`;
const PrecificaoBatataRoot = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 1080px;
  text-align: left;
  font-size: var(--h6-size);
  color: var(--color-white);
  font-family: var(--small);
`;

const PrecificaoBatata = () => {
  const navigate = useNavigate();

  const onSoulBenBordadoImageClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onSUGESTO1TextClick = useCallback(() => {
    navigate("/selecione-um-cliente");
  }, [navigate]);

  const onSUGESTO2TextClick = useCallback(() => {
    navigate("/busque-ou-pesquise-por-um-ingrediente");
  }, [navigate]);

  const onFrameContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='telaContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onPginaInicialClick = useCallback(() => {
    navigate("/perfil-batata");
  }, [navigate]);

  return (
    <PrecificaoBatataRoot>
      <Tela1 data-scroll-to="telaContainer">
        <WindowsDesktopChromeBrows>
          <ToolbarBrowserControls>
            <Tabs>
              <EndTab>
                <TabActive>
                  <CurveLIcon alt="" src="/curve-l.svg" />
                  <Content>
                    <Favicon alt="" src="/favicon@2x.png" />
                    <TabName>
                      <Google>{`Google               `}</Google>
                      <Span>.</Span>
                    </TabName>
                    <CloseIcon alt="" src="/close.svg" />
                  </Content>
                  <CurveRIcon alt="" src="/curve-r.svg" />
                </TabActive>
                <PlusIcon alt="" src="/plus.svg" />
              </EndTab>
            </Tabs>
            <Right>
              <ChevronRightIcon alt="" src="/chevron-right@2x.png" />
              <RemoveIcon alt="" src="/remove.svg" />
              <PlusIcon1>
                <RectangleParent>
                  <FrameChild />
                  <FrameItem alt="" src="/rectangle-9.svg" />
                </RectangleParent>
              </PlusIcon1>
              <RemoveIcon alt="" src="/close1.svg" />
            </Right>
          </ToolbarBrowserControls>
          <ToolbarUrlControls1>
            <ToolbarUrlControls />
            <RightLockedIcons>
              <Favicon alt="" src="/icon--more@2x.png" />
            </RightLockedIcons>
            <Url>
              <Left>
                <IconSecure alt="" src="/icon--secure@2x.png" />
                <Text>
                  <Domaincom>domain.com</Domaincom>
                  <Subdomain>/subdomain/</Subdomain>
                </Text>
              </Left>
              <Favicon alt="" src="/icon--favorite@2x.png" />
            </Url>
            <LeftLockedIcons>
              <BackIcon alt="" src="/back.svg" />
              <BackIcon alt="" src="/forward.svg" />
              <BackIcon alt="" src="/refresh.svg" />
              <Favicon alt="" src="/home@2x.png" />
            </LeftLockedIcons>
          </ToolbarUrlControls1>
        </WindowsDesktopChromeBrows>
        <PginaInicialParent>
          <PginaInicial1>
            <ComoVoceEst>{`Página inicial >`}</ComoVoceEst>
          </PginaInicial1>
          <PginaInicial1>
            <ComoVoceEst>{`Página inicial >`}</ComoVoceEst>
          </PginaInicial1>
        </PginaInicialParent>
      </Tela1>
      <ChamadaPrincipal1>
        <ComoVoceEst>Batata</ComoVoceEst>
      </ChamadaPrincipal1>
      <PrecificaoBatataChild alt="" src="/group-8@2x.png" />
      <ChamadaPrincipal1>
        <ComoVoceEst>Batata</ComoVoceEst>
      </ChamadaPrincipal1>
      <PrecificaoBatataChild alt="" src="/group-8@2x.png" />
      <FrameParent>
        <Data1Parent>
          <Data>Data 1</Data>
          <Data1>Data 2</Data1>
          <Data1>Data 3</Data1>
          <Data1>Data 4</Data1>
          <Data1>Data 5</Data1>
          <Data1>Data 6</Data1>
        </Data1Parent>
        <Preo1Parent>
          <Data1>Preço 1</Data1>
          <Data1>Preço 2</Data1>
          <Data1>Preço 3</Data1>
          <Data1>Preço 4</Data1>
          <Data1>Preço 5</Data1>
          <Data1>Preço 6</Data1>
        </Preo1Parent>
        <LineParent>
          <FrameInner />
          <LineDiv />
          <FrameInner />
          <LineDiv />
          <FrameInner />
          <LineDiv />
        </LineParent>
      </FrameParent>
      <Topbar1>
        <SoulbenBordadoIcon
          alt=""
          src="/soulben-bordado@2x.png"
          onClick={onSoulBenBordadoImageClick}
        />
        <LetciaParent>
          <Letcia>Letícia</Letcia>
          <GroupChild alt="" src="/rectangle-35@2x.png" />
        </LetciaParent>
        <SidebarParent>
          <Sidebar1>
            <Sugesto onClick={onSUGESTO1TextClick}>Clientes</Sugesto>
            <Sugesto onClick={onSUGESTO2TextClick}>Ingredientes</Sugesto>
            <Sugesto1>Adicionar um cliente</Sugesto1>
            <Sugesto1>Adicionar um ingrediente</Sugesto1>
            <Sugesto1>Adicionar um prato</Sugesto1>
          </Sidebar1>
          <Component2>
            <PesquisePorIngredientes>
              Pesquise por: Ingredientes, Receitas ou Clientes
            </PesquisePorIngredientes>
            <Component5Child />
            <Component5Item alt="" src="/group-4.svg" />
          </Component2>
        </SidebarParent>
      </Topbar1>
      <MenuBar>
        <PesquisePorIngredientes>
          Pesquise por ingredientes
        </PesquisePorIngredientes>
        <Component5Child />
        <Component5Item alt="" src="/group-4.svg" />
      </MenuBar>
      <HistricoDePrecificao>Histórico de precificação</HistricoDePrecificao>
      <PginaInicialGroup onClick={onFrameContainerClick}>
        <PginaInicial1>
          <ComoVoceEst>{`Página inicial >`}</ComoVoceEst>
        </PginaInicial1>
        <PginaInicial1>
          <ComoVoceEst>{`Ingrediente 1 >`}</ComoVoceEst>
        </PginaInicial1>
      </PginaInicialGroup>
      <PginaInicial2 onClick={onFrameContainerClick}>
        <ComoVoceEst>{`Ingredientes 2 >`}</ComoVoceEst>
      </PginaInicial2>
      <PginaInicial3 onClick={onPginaInicialClick}>
        <ComoVoceEst>{`Cebola >`}</ComoVoceEst>
      </PginaInicial3>
      <PginaInicial4 onClick={onFrameContainerClick}>
        <ComoVoceEst>Resumo</ComoVoceEst>
      </PginaInicial4>
    </PrecificaoBatataRoot>
  );
};

export default PrecificaoBatata;
