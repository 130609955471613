// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBGW7LAhnShataV1w1B2JYdu_F4J4OgdXk",
  authDomain: "soulben-webapp.firebaseapp.com",
  projectId: "soulben-webapp",
  storageBucket: "soulben-webapp.appspot.com",
  messagingSenderId: "206353486464",
  appId: "1:206353486464:web:821613c09d8dd61bdd8c65"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
