import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAuth, signOut } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import GlobalSearch from "./GlobalSearch";

const HeaderContainer = styled.header`
  width: 100%;
  background-color: #000;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-bottom: 2px solid var(--color-orange);
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
`;

const TopSection = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
`;

const Logo = styled.img`
  height: 75px;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const SearchContainer = styled.div`
  flex: 1;
  max-width: 600px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--br-5xs);
  overflow: hidden;
  height: 36px; /* Ensure the height is fixed */
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px 10px; /* Adjust padding to fit within the height */
  border: none;
  outline: none;
  font-size: 1rem;
`;

const SearchButton = styled.button`
  width: 36px; /* Same height and width for a square button */
  height: 36px;
  background-color: var(--color-darkorange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const UserInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const UserImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: var(--br-5xs);
  object-fit: cover;
`;

const UserName = styled.div`
  color: var(--color-white);
  font-size: 1rem;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: var(--br-5xs);
  display: ${(props) => (props.show ? "block" : "none")};
  z-index: 1000;
`;

const MenuSection = styled.nav`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;

  &:hover {
    color: var(--color-orange);
    text-decoration: underline;
  }
`;

const FunctionsHeader = ({ onLogoClick, onClientClick, onIngredientClick, onUserDataFetched }) => {
  const [userData, setUserData] = useState({ name: "", picture: "/default-avatar.png" });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);

      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const { name, picture } = data;
          setUserData({
            name,
            picture: picture ? `data:image/png;base64,${picture}` : "/default-avatar.png",
          });
          if (onUserDataFetched) {
            onUserDataFetched({ name, picture: picture ? `data:image/png;base64,${picture}` : "/default-avatar.png" });
          }
        }
      });
    }
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <HeaderContainer>
      <TopSection>
        <Logo src="/soulben-bordado@2x.png" alt="SoulBen Logo" onClick={onLogoClick} />
        <SearchContainer>
          <GlobalSearch placeholder="Pesquise por: Ingredientes, Receitas ou Clientes" />
          <SearchButton>
            <img src="/group-4.svg" alt="Search Icon" />
          </SearchButton>
        </SearchContainer>
        <UserInfoContainer onClick={() => setDropdownOpen(!dropdownOpen)}>
          <UserImage src={userData.picture} alt="User Image" />
          <UserName>{userData.name}</UserName>
          <DropdownMenu show={dropdownOpen}>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </DropdownMenu>
        </UserInfoContainer>
      </TopSection>
      <MenuSection>
        <MenuItem onClick={onClientClick}>Clientes</MenuItem>
        <MenuItem onClick={onIngredientClick}>Ingredientes</MenuItem>
        <MenuItem>Adicionar um cliente</MenuItem>
        <MenuItem>Adicionar um ingrediente</MenuItem>
        <MenuItem>Adicionar uma receita</MenuItem>
      </MenuSection>
    </HeaderContainer>
  );
};

export default FunctionsHeader;
